import React, { useRef, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { format, parseISO } from 'date-fns';
import { TonConnectButton, useTonAddress, useTonConnectUI, useTonWallet, TonConnect } from '@tonconnect/ui-react';

import { InfoIcon } from '@chakra-ui/icons';

//Конфиг
import config from '../config';

import {
    Badge,
    Stack,
    Switch,
    Tag,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    useToast,
    Spinner,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Input,
    Textarea,
    useDisclosure,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Portal,
    Box,
    Code,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

//Сторы
import playerStore from '../store/playerStore';

//Иконки
import {
    AiOutlineUser,
    AiOutlineEye,
    AiOutlineTeam,
    AiOutlineDelete,
    AiOutlineEdit
} from "react-icons/ai";

import { PhoneIcon, AddIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons'

//Стили
import '../css/main.css';
import '../css/withdrawal.css';

//Компоненты
import SideBar from '../components/SideBar';
import Header from '../components/Header';

//сторы
import PlayerStore from '../store/playerStore';

const Withdrawal = observer(() => {

    //Подключение кошелька
    const [isConnected, setIsConnected] = useState(false);
    const [tonConnectUI, setTonConnectUI] = useTonConnectUI({
        manifestUrl: 'https://vabankgame.ru/tonconnect-manifest.json', // Укажите URL вашего манифеста
        network: 'testnet', // Явно указываем Testnet
    });

    ////Для кошелька
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const wallet = useTonWallet();//Информация о кошельке 
    console.log(wallet);
    useEffect(() => {
        // Проверка статуса подключения
        if (tonConnectUI.account) {
            setIsConnected(true); // Пользователь подключен
            console.log('tonConnectUI.account --->', tonConnectUI.account);
            console.log('useTonWallet --->', JSON.stringify(wallet, null, 1));
        } else {
            setIsConnected(false); // Кошелек не подключен
            //toast.info("Кошелёк отключён!", {
            //    position: "bottom-center",
            //    autoClose: 3000,
            //    hideProgressBar: false,
            //    closeOnClick: true,
            //    pauseOnHover: true,
            //    draggable: true,
            //    progress: undefined,
            //    theme: "dark",
            //    transition: Slide,
            //});
        }
    }, [tonConnectUI.account]); // Следим за изменением аккаунта


    // Функция для получения token
    const [usertoken, setUsertoken] = useState(null);
    const getToken = async () => {
        try {
            const utoken = await AsyncStorage.getItem('token');
            if (utoken !== null) {
                console.log('Token retrieved:', utoken);
                setUsertoken(utoken)
                return utoken;
            }
        } catch (error) {
            console.error('Error retrieving token', error);
        }
    };
    useEffect(() => {
        getToken();
    }, []);

    //Получаем список выводов
    const [withdrawals, setWithdrawals] = useState(null);
    //Параметры 
    const sort = 1;
    const sorting = 'desc';
    const withdrawalsList = (t, page, sort, sorting) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/withdrawallist?page=${page}&sort=${sort}&sorting=${sorting}`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setWithdrawals(response.data);
                console.log('СПИСОК ВЫВОДОВ: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }
    //Вызываем список пользователей при загрузке страницы
    useEffect(() => {
        if (usertoken) {
            withdrawalsList(usertoken, 1, sort, sorting); //Токен / page / включение сортировки / направление сортировки asc или desc
        }
    }, [usertoken]);

    //Переходим на страницу
    const getPage = (page) => {
        withdrawalsList(usertoken, page, sort, sorting);
    }

    //Popover Всплывающая панелька с данными пользователя
    const PopoverInfo = ({ user }) => {
        const initRef = React.useRef()
        return (
            <Popover
                arrowSize={10}
                closeOnBlur={true}
                placement='left'
                initialFocusRef={initRef}
            >
                {({ isOpen, onClose }) => (
                    <>
                        <PopoverTrigger>
                            <Button>{user?.idchat}</Button>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent>
                                <PopoverHeader>{user?.idchat}</PopoverHeader>
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <Box>
                                        <div style={{ fontSize: 12, color: '#999999', marginBottom: 10 }}>Адрес TON (friendly):</div>
                                        <div style={{ wordBreak: 'break-all' }}><Code colorScheme='green'>{user?.friendly_address}</Code></div>
                                    </Box>
                                    <br />
                                    <Box>
                                        <Stat>
                                            <StatLabel style={{ fontSize: 12, color: '#999999' }}>Баланс USDT</StatLabel>
                                            <StatNumber>{user?.usdt}</StatNumber>
                                        </Stat>
                                        <br />
                                        <Stat>
                                            <StatLabel style={{ fontSize: 12, color: '#999999' }}>Баланс VaBANK</StatLabel>
                                            <StatNumber>{user?.balance}</StatNumber>
                                        </Stat>
                                    </Box>
                                </PopoverBody>
                                <PopoverFooter style={{ fontSize: 12 }}>ref code: {user?.refcode}</PopoverFooter>
                            </PopoverContent>
                        </Portal>
                    </>
                )}
            </Popover>
        )
    }

    //Модальное окно транзакции
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [curentUser, setCurentUser] = useState(null);

    const handleSizeClick = (user) => {
        setCurentUser(user);
        onOpen();
    }

    const ModalTransaction = ({ item }) => {
        return (
            <>
                <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
                    <ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    //bg='none'
                    //backdropFilter='auto'
                    //backdropInvert='80%'
                    //backdropBlur='2px'
                    />
                    <ModalContent>
                        <ModalHeader>Вывод пользователю @{item?.user?.idchat}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Будет совершена транзакция с Вашего адреса TON на адрес:
                            <Code colorScheme='green' style={{ marginBottom: 15 }}>{item?.user?.friendly_address}</Code>
                            <Stat>
                                <StatLabel style={{ fontSize: 12, color: '#999999' }}>Вывод в размере USDT:</StatLabel>
                                <div className="summa_big">{item?.amount_usdt}  USDT <span className='summa_comission'> - {withdrawals?.comission} USDT <span className='summa_comission_text'>(комиссия)</span></span></div>
                            </Stat>
                            <br />

                            <Stat>
                                <StatLabel style={{ fontSize: 12, color: '#999999' }}>Сумма с комиссией в USDT:</StatLabel>
                                <div className="summa_big">{item?.amount_usdt - withdrawals?.comission}  USDT</div>
                            </Stat>
                            <br />

                            <Stat>
                                <StatLabel style={{ fontSize: 12, color: '#999999' }}>Сумма в TON:</StatLabel>
                                <div className="summa_big">{((item?.amount_usdt - withdrawals?.comission) / withdrawals?.tonPrice).toFixed(3)} TON</div>
                                <StatHelpText>Сумма будет пересчитана на момент транзакции по актуальному курсу TON</StatHelpText>
                            </Stat>
                        </ModalBody>
                        <ModalFooter>
                            <Stack spacing={4} direction='row' align='center'>
                                <Button
                                    colorScheme='teal'
                                    onClick={() => {
                                        handleTransaction(item.id, ((item?.amount_usdt - withdrawals?.comission) / withdrawals?.tonPrice).toFixed(3), item?.user?.friendly_address);
                                        onClose();
                                    }}
                                >Подтвердить</Button>
                                <Button onClick={onClose}>Закрыть</Button>
                            </Stack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }

    //Создаем транзакцию
    const handleTransaction = async (id_withdrawal, tonAmount, tonAddress) => {
        const transaction = {
            validUntil: Date.now() + 5 * 60 * 1000, // Транзакция будет валидна в течение 5 минут
            messages: [
                {
                    address: tonAddress, // Адрес получателя
                    amount: tonAmount * 1000000000, // Сумма в нанотоннах (0.02 Toncoin)
                },
            ],
        };

        try {
            // Проверка на допустимость сети (раскомментируйте при необходимости)
            // if (wallet?.account?.chain !== '-1' && wallet?.account?.chain !== '-239') {
            //     alert('Transaction is allowed only on the mainnet.');
            //     withdrawalconfirm(id_withdrawal);
            //     return;
            // }

            await tonConnectUI.sendTransaction(transaction); // Отправляем транзакцию
            alert('Transaction sent successfully!');
            withdrawalconfirm(id_withdrawal); // Подтверждение транзакции
        } catch (error) {
            console.error('Error sending transaction:', error);
            alert('Failed to send transaction.');
        }
    };

    //Подтверждение транзакции
    const withdrawalconfirm = (id_windraval) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/withdrawalconfirm`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usertoken}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            id_windraval: id_windraval,
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ИНФОРМАЦИЯ ТРАНЗАКЦИИ ВЫВОДА: ', JSON.stringify(response?.data, null, 1));
                updatedWithdrawals(response?.data?.windraval?.id, response?.data?.windraval?.date_executed);
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка СОХРАНЕНИЯ ИНФОРМАЦИИ 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса СОХРАНЕНИЯ ИНФОРМАЦИИ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка СОХРАНЕНИЯ ИНФОРМАЦИИ:', error.message);
                }
            });
    }

    //Обновляем на фронтенде статус
    const updatedWithdrawals = (id, date) => {
        setWithdrawals(prevWithdrawals => ({
            ...prevWithdrawals,
            withdrawals: {
                ...prevWithdrawals.withdrawals,
                data: prevWithdrawals.withdrawals.data.map(withdrawal =>
                    withdrawal.id === id
                        ? { ...withdrawal, status: "executed", date_executed: date } // Обновляем статус и время обновления
                        : withdrawal // Оставляем объект без изменений
                )
            }
        }));
    };

    return (
        <>
            {playerStore?.player?.token ? (
                <div className='main flexrow'>
                    <div className='sidebar flexcolumn'>
                        <SideBar />
                    </div>
                    <div className='main-contents'>
                        <Header title={'Выводы'} />
                        <div className='main-blok'>

                            <div className='buttonWallet'>
                                <TonConnectButton className="iduser_button_ton" />
                            </div>

                            <div className='panel withdrawalListWrap'>
                                <div className='scrollingVerticale'>
                                    <div className='panelPaddings'>

                                        <div className='withdrawalList'>

                                            <div className='withdrawalHeader'>
                                                <div className='withdrawalAmount'>Сумма</div>
                                                <div className='withdrawalStatus'>Статус</div>
                                                <div className='withdrawalExecuted'>Дата исполнения</div>
                                                <div className='withdrawalOldbalance'>Старый баланс</div>
                                                <div className='withdrawalCreated'>Дата создания</div>
                                                <div className='withdrawalIdchat'>IDCHAT</div>
                                                <div className='withdrawalButtons'>Действия</div>
                                            </div>
                                            {console.log(JSON.stringify(withdrawals, null, 1))}
                                            {withdrawals && withdrawals?.withdrawals?.data && withdrawals?.withdrawals?.data.length > 0 ? (
                                                withdrawals?.withdrawals?.data?.map((item, index) => (
                                                    <div className='withdrawalItem'>
                                                        <div className='withdrawalItem_amount withdrawal_amount'><div className='withdrawal_index'>{index}</div>{(item?.amount_usdt - withdrawals?.comission).toFixed(3)} usdt - {((item?.amount_usdt - withdrawals?.comission) / withdrawals?.tonPrice).toFixed(3)} TON</div>
                                                        <div className='withdrawalItem_status'>{item?.status == 'new' ? <div className='status_new'></div> : item?.status == 'executed' ? <div className='status_executed'></div> : item?.status == 'cancel' ? <div className='status_cancel'></div> : null} {item.status} </div>
                                                        <div className='withdrawalItem_date_executed'>{item.date_executed ? item.date_executed : 'Ожидание подтверждения'}</div>
                                                        <div className='withdrawalItem_old_balance_usdt'>{item.old_balance_usdt} USDT</div>
                                                        <div className='withdrawalItem_date_created'>{item.created_at}</div>
                                                        <div className='withdrawalItem_idchat'>
                                                            <PopoverInfo
                                                                user={item?.user}
                                                            />
                                                        </div>
                                                        <div className='withdrawalItem_buttons'>
                                                            {userFriendlyAddress && item?.status == 'new' ? (
                                                                <Stack spacing={4} direction='row' align='center'>
                                                                    <Button
                                                                        colorScheme='teal'
                                                                        size='xs'
                                                                        onClick={() => handleSizeClick(item)}>
                                                                        Одобрить
                                                                    </Button>
                                                                    <Button colorScheme='red' size='xs'>
                                                                        Отменить
                                                                    </Button>
                                                                </Stack>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p></p>
                                            )}

                                        </div>

                                        <div className='paginations'>
                                            {withdrawals && withdrawals.links && withdrawals.links.length > 0 ? (
                                                withdrawals.links.map((link, index) => (
                                                    <button className={withdrawals.current_page == link.label ? 'page_active' : null} onClick={() => getPage(link.label)}>
                                                        {link.label}
                                                    </button>
                                                ))
                                            ) : (
                                                <p></p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (
                <div className='errorWrap'>
                    <div className='error'>ERROR 401</div>
                </div>
            )}

            <ModalTransaction item={curentUser} />
        </>
    );
});

export default Withdrawal;

import React, { useRef, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { format, parseISO } from 'date-fns';

//Конфиг
import config from '../config';

import {
    useToast,
    Spinner,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,          // Добавляем импорт Button
    Input,           // Добавляем импорт Input
    useDisclosure,    // Добавляем импорт useDisclosure
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    TabIndicator,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Heading,
    Text,
    SimpleGrid,
    Switch,
} from '@chakra-ui/react';

//Иконки
import {
    AiOutlineUser,
    AiOutlineEye,
    AiOutlineTeam,
    AiOutlineDelete,
    AiOutlineEdit
} from "react-icons/ai";

//Сторы
import playerStore from '../store/playerStore';

//Стили
import '../css/users.css';
import '../css/main.css';

//Компоненты
import SideBar from '../components/SideBar';
import Header from '../components/Header';

//Для даты
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Padding } from '@mui/icons-material';


const Users = observer(() => {

    //Всплывающие сообщения
    const toast = useToast();

    //Параметры 
    const sort = 1;
    const sorting = 'desc';

    // Функция для получения token
    const [usertoken, setUsertoken] = useState(null);
    const getToken = async () => {
        try {
            const utoken = await AsyncStorage.getItem('token');
            if (utoken !== null) {
                console.log('Token retrieved:', utoken);
                setUsertoken(utoken)
                return utoken;
            }
        } catch (error) {
            console.error('Error retrieving token', error);
        }
    };
    useEffect(() => {
        getToken();
    }, []);

    //Получаем список пользователей у которых есть NFT
    const [usersNft, setUsersNft] = useState(null);
    const usersNftList = (t, page, sort, sorting) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/listuserinnft?page=${page}&sort=${sort}&sorting=${sorting}`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setUsersNft(response.data);
                console.log('СПИСОК ПОЛЗОВАТЕЛЕЙ C NFT: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка СПИСОК ПОЛЗОВАТЕЛЕЙ C NFT 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса СПИСОК ПОЛЗОВАТЕЛЕЙ C NFT:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка СПИСОК ПОЛЗОВАТЕЛЕЙ C NFT:', error.message);
                }
            });
    }

    //Для выбора даты
    // Состояние для хранения выбранной даты
    const [selectedDate, setSelectedDate] = useState('2023-09-01');
    const [minSumm, setMinSumm] = useState(0);
    const [maxSumm, setMaxSumm] = useState(1000000);
    const [applyFilter, setApplyFilter] = useState(0); // Переключалка Switch Изначальное значение 0 

    const handleSwitchChange = (e) => {
        setApplyFilter(e.target.checked ? 1 : 0); // Если переключатель включён, значение 1, иначе 0
    };

    // Обработчик изменения даты
    //const handleDateChange = (date) => {
    //    setSelectedDate(date);
    //};
    const handleDateChange = (date) => {
        const formattedDate = date ? format(date, 'yyyy-MM-dd') : ''; // Форматируем дату в строку
        setSelectedDate(formattedDate); // Обновляем состояние строкой
        console.log(selectedDate);
    };

    //Изменение минимальной суммы NFT minSumm
    const handleMinSumm = (event) => {
        setMinSumm(event.target.value)
    }

    //Изменение максимальной суммы NFT maxSumm
    const handleMaxSumm = (event) => {
        setMaxSumm(event.target.value)
    }

    //Получаем список кользователей
    const [users, setUsers] = useState(null);
    const [testSort, setTestSorts] = useState('Отсортировать'); //Просто текст на кнопке
    const usersList = (t, page, sort, sorting) => {
        setTestSorts('Идёт сортировка');
        const apiUrl = config.apiBaseUrl + `/api/api/v1/users?page=${page}&sort=${sort}&sorting=${sorting}&from_date=${selectedDate}&min_nft_sum=${minSumm}&max_nft_sum=${maxSumm}&apply_filter=${applyFilter}`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setUsers(response.data);
                console.log('СПИСОК ПОЛЗОВАТЕЛЕЙ: ', JSON.stringify(response.data, null, 1));
                setTestSorts('Отсортировать');
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }
    //Вызываем список пользователей при загрузке страницы
    useEffect(() => {
        if (usertoken) {
            usersList(usertoken, 1, sort, sorting); //Токен / page / включение сортировки / направление сортировки asc или desc
            usersNftList(usertoken, 1, sort, sorting);
        }
    }, [usertoken]);



    //Проверяем существование фото
    const handleImageError = (event) => {
        event.target.src = '/images/ava_default.png';
    };


    //Текущая страница
    const location = useLocation();
    const currentPage = new URLSearchParams(location.search).get('page') || 1;

    // Функция, которая будет выполняться при изменении страницы
    const fetchDataForPage = (page) => {
        console.log(`Fetching data for page ${page}`);
        // Твоя логика для загрузки данных
    };

    // Вызов функции при изменении параметра page
    useEffect(() => {
        fetchDataForPage(currentPage);
    }, [currentPage]); // Следим за изменением currentPage

    //Переходим на страницу
    const getPage = (page) => {
        usersList(usertoken, page, sort, sorting);
    }

    const getPageNft = (page) => {
        usersNftList(usertoken, page);
        console.log('Страница номер: ', page);
    }

    const getPageReferals = (page, user) => {
        getReferals(usertoken, user.id, page, sort, sorting);
    }


    //Drawer==========
    const referalsDisclosure = useDisclosure();
    const editDisclosure = useDisclosure();
    const deleteDisclosure = useDisclosure();
    const btnRef = useRef();
    const btnEditRef = useRef();
    const btnDeleteRef = useRef();

    const [titleDrawer, setTitleDrawer] = useState('...');

    //Функция взять реффералов с сервера
    const [userReferals, setUserReferals] = useState([]);
    const [countReferals, setCountReferals] = useState(null);
    const getReferals = (t, iduser, page, sort, sorting) => {
        setUserReferals('wait');
        setCountReferals(null);
        const apiUrl = config.apiBaseUrl + `/api/api/v1/admingetreferals?page=${page}&sort=${sort}&sorting=${sorting}&iduser=${iduser}`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setUserReferals(response.data);
                setCountReferals(response?.data?.lineCounts);
                console.log('СПИСОК РЕФЕРАЛОВ: ', JSON.stringify(response.data, null, 1));

            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка РЕФЕРАЛОВ:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса РЕФЕРАЛОВ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка РЕФЕРАЛОВ:', error.message);
                }
            });
    }


    //Функция для запроса рефералов isOpen
    const [curentUserReferal, setCurentUserReferal] = useState(null);
    const referalsUser = (event, title, user) => {
        //event.preventDefault();
        setUserReferals('wait');
        setTitleDrawer(title);
        referalsDisclosure.onOpen();
        //console.log(JSON.stringify(user));
        getReferals(usertoken, user.id, 1, sort, sorting);
        setCurentUserReferal(user);
        console.log('колличество по уровням:::', JSON.stringify(countReferals, null, 1));
    }

    //Функция удалить пользователя
    const deleteUser = (event, title, user) => {
        //event.preventDefault();
        setTitleDrawer(title);
        deleteDisclosure.onOpen();
        console.log(JSON.stringify(user));
    }

    //Функция редактировать пользователя
    const editUser = (event, title, user) => {
        //event.preventDefault();
        setTitleDrawer(title);
        editDisclosure.onOpen();
        getInfoUser(usertoken, user.idchat);
    }
    //=============== END

    //Форма редактирования пользователя
    const [inputRefCode, setInputRefCode] = useState(null);
    const [inputScrolling, setInputScrolling] = useState(null);
    const [inputBalance, setInputBalance] = useState(null);
    const [inputLevel, setInputLevel] = useState(null);
    const [inputParent_user, setInputParent_user] = useState(null);
    const [inputBybit_link, setInputBybit_link] = useState(null);
    const [inputBybit_reflink, setInputBybit_reflink] = useState(null);

    // Обработчик изменения текста в поле ввода
    const handleRefCode = (event) => {
        setInputRefCode(event.target.value)
    }
    const handleScrolling = (event) => {
        setInputScrolling(event.target.value)
    }
    const handleBalance = (event) => {
        setInputBalance(event.target.value)
    }
    const handleLevel = (event) => {
        setInputLevel(event.target.value)
    }
    const handleParent_user = (event) => {
        setInputParent_user(event.target.value)
    }
    const handleBybit_link = (event) => {
        setInputBybit_link(event.target.value)
    }
    const handleBybit_reflink = (event) => {
        setInputBybit_reflink(event.target.value)
    }

    //Функция запрашивает с сервера информацию о пользователе (всю)
    const [infoUser, setInfoUser] = useState(null);
    const [nftUser, setNftUser] = useState([]);
    const getInfoUser = (t, idchat) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/geinfouser?idchat=${idchat}`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setInfoUser(response?.data?.user);
                setInputRefCode(response?.data?.user.refcode);
                setInputScrolling(response?.data?.user.scrolling);
                setInputBalance(response?.data?.user.balance);
                setInputLevel(response?.data?.user.level);
                setInputParent_user(response?.data?.user.parent_user);
                setInputBybit_link(response?.data?.user.bybit?.bybit_link);
                setInputBybit_reflink(response?.data?.user.bybit?.bybit_reflink);
                setNftUser(response?.data?.usernft);
                console.log('ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ: ', JSON.stringify(response?.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ:', error.message);
                }
            });
    }

    //Функция сохраняет отправленную инфу
    const saveInfoUser = (t, idchat, refcode, scrolling, balance, level, parent_user, bybit_link, bybit_reflink) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/saveinfouser`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {
            idchat: idchat,
            refcode: refcode,
            scrolling: scrolling,
            balance: balance,
            level: level,
            parent_user: parent_user,
            bybit_link: bybit_link,
            bybit_reflink: bybit_reflink
        };

        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ СОХРАНЕНА: ', JSON.stringify(response.data, null, 1));
                editDisclosure.onClose();
                toast({
                    title: 'Пользователь сохранен!',
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                })
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка СОХРАНЕНИЯ ИНФОРМАЦИИ 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса СОХРАНЕНИЯ ИНФОРМАЦИИ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка СОХРАНЕНИЯ ИНФОРМАЦИИ:', error.message);
                }
            });
    }


    //Шаблон реферала
    const TemplateReferal = ({ user, parent }) => {
        return (
            <div className='userItem' key={user.id}>
                <div className='userId'>{user.id}</div>
                <div className='userPhotoWrap'>
                    {user.user_photo ? (
                        <img
                            className='userPhoto'
                            src={user.user_photo}
                            alt="User Photo"
                            onError={handleImageError}
                        />
                    ) : (
                        <img className='userPhoto' src="/images/ava_default.png" alt="No Photo Available" />
                    )}
                </div>

                <div className='userNameWrap'>
                    <div className='userName'>{user.username ? <span className="userNameIsName">{user.username} </span> : <span className="userNameNoName">Нет username</span>}</div>
                    <div className='userIdChat'>{user.idchat}</div>
                </div>

                <div className='userBalance'>{user.balance}</div>
                <div className='userParent'>{user.parent_user}
                    <div className='userParentLine'>{user.line ? user.line + ' линия' : '...'}</div>
                </div>
                <div className='userRefCode'>{user.refcode}</div>
                <div className='userCreaatedAt'>{format(parseISO(user.created_at), 'dd-MM-yyyy')}</div>

            </div>
        );
    }

    //ДЛЯ ПОИСКА
    //Поиск по списку
    const [inputRefSearch, setInputRefSearch] = useState(null);

    // Обработчик изменения текста в поле ввода
    const handleRefSearch = (event) => {
        setInputRefSearch(event.target.value);
        console.log(event.target.value);
    }
    //Отправка в поиск
    const [searchResult, setSearchResult] = useState([]);
    const search = (t, value) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/searchuser?search=${value}`;

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        ////Отправляемые даныне
        //const dataRec = {
        //};

        axios.get(apiUrl, { headers })
            .then(response => {
                console.log('ПОИСК ПОЛЬЗОВАТЕЛЯ: ', JSON.stringify(response.data, null, 1));
                if (response.data.errorcode == 0) {
                    setSearchResult(response.data.users);
                }
                else {
                    setSearchResult([]);
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка ПОИСКА ПОЛЬЗОВАТЕЛЯ 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка ПОИСКА ПОЛЬЗОВАТЕЛЯ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка ПОИСКА ПОЛЬЗОВАТЕЛЯ:', error.message);
                }
            });
    }

    //Переключалка СПИСОК/ПОИСК
    const [switchPage, setSwitchPage] = useState(0);

    const switchPageFunction = (val) => {
        setSwitchPage(val);
    }

    return (
        <>
            {usertoken ? (
                <div className='main flexrow'>
                    <div className='sidebar flexcolumn'>
                        <SideBar />
                    </div>
                    <div className='main-contents'>
                        <Header title={'Пользователи'} />

                        <div className='main-blok'>

                            {/* ПЕРЕКЛЮЧАЛКА МЕЖДУ ПОИСКОМ И СПИСОМ */}
                            <div className='switchButtons'>
                                <div className='switchButtonsRow'>
                                    <div className='switchButtonsCol'>
                                        <Button
                                            className='homeSeachSubmit'
                                            colorScheme='teal'
                                            variant='outline'
                                            onClick={() => switchPageFunction(0)}>
                                            ПОЛЬЗОВАТЕЛИ
                                        </Button>
                                    </div>
                                    <div className='switchButtonsCol'>
                                        <Button
                                            className='homeSeachSubmit'
                                            colorScheme='teal'
                                            variant='outline'
                                            onClick={() => switchPageFunction(2)}>
                                            ПОЛЬЗОВАТЕЛИ С NFT
                                        </Button>
                                    </div>
                                    <div className='switchButtonsCol'>
                                        <Button
                                            className='homeSeachSubmit'
                                            colorScheme='teal'
                                            variant='outline'
                                            onClick={() => switchPageFunction(1)}>
                                            ПОИСК
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {/* ПЕРЕКЛЮЧАЛКА МЕЖДУ ПОИСКОМ И СПИСОМ END */}

                            {switchPage == 1 ? (
                                <>
                                    {/* ПОИСКОВАЯ СТРОКА */}
                                    <div className='homeSeachWrap'>
                                        <div className='homeSeachRow'>
                                            <div className='homeSeachcol'>
                                                <FormControl>
                                                    <FormLabel className='formLabel'>ПОИСК. Введите имя username или idchat</FormLabel>
                                                    <div className='homeSeachRowForm'>
                                                        <Input
                                                            type="text"
                                                            value={inputRefSearch}
                                                            onChange={handleRefSearch}
                                                            className='formSearchInput'
                                                        />
                                                        <Button
                                                            className='homeSeachSubmit'
                                                            colorScheme='teal'
                                                            variant='outline'
                                                            onClick={() => search(usertoken, inputRefSearch)}>
                                                            НАЙТИ
                                                        </Button>
                                                    </div>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className='searchResult'>
                                            {searchResult.length != 0 ? (
                                                <div className='panel'>
                                                    <div className='headerPanel'>
                                                        <div className='headerPanelRow'>
                                                            <div className='headerPanelCol photoHeader'>
                                                                фото
                                                            </div>
                                                            <div className='headerPanelCol usernameHeader'>
                                                                username / idchat
                                                            </div>
                                                            <div className='headerPanelCol balanceHeader'>
                                                                баланс
                                                            </div>
                                                            <div className='headerPanelCol parentHeader'>
                                                                parent
                                                            </div>
                                                            <div className='headerPanelCol refcodeHeader'>
                                                                Реф. код
                                                            </div>
                                                            <div className='headerPanelCol dateHeader'>
                                                                Дата создания
                                                            </div>
                                                            <div className='headerPanelCol buttonsHeader'>
                                                                Управление
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='scrollingVerticale'>
                                                        <div className='scrollingVerticaleInner'>
                                                            {searchResult.map((user, index) => (
                                                                <div className='userItem' key={user.id}>
                                                                    <div className='userId'>{user.id}</div>
                                                                    <div className='userPhotoWrap'>
                                                                        {user.user_photo ? (
                                                                            <img
                                                                                className='userPhoto'
                                                                                src={user.user_photo}
                                                                                alt="User Photo"
                                                                                onError={handleImageError}
                                                                            />
                                                                        ) : (
                                                                            <img className='userPhoto' src="/images/ava_default.png" alt="No Photo Available" />
                                                                        )}
                                                                    </div>

                                                                    <div className='userNameWrap'>
                                                                        <div className='userName'>{user.username ? <span className="userNameIsName">{user.username} </span> : <span className="userNameNoName">Нет username</span>}</div>
                                                                        <div className='userIdChat'>{user.idchat}</div>
                                                                    </div>

                                                                    <div className='userBalance'>
                                                                        VaBank: {user.balance}<br />
                                                                        USDT: {user.usdt}<br />
                                                                        VBK: {user.vbk}<br />
                                                                        VBK VEST: {user.vbk_vest}<br />
                                                                    </div>
                                                                    <div className='userParent'>{user.parent_user ? user.parent_user : '...'}</div>
                                                                    <div className='userRefCode'>{user.refcode}</div>
                                                                    <div className='userCreaatedAt'>{format(parseISO(user.created_at), 'dd-MM-yyyy')}</div>
                                                                    <div className='userButtons'>
                                                                        <button ref={btnRef} className='buttonIcon' onClick={(event) => referalsUser(event, 'Рефералы пользователя c id ' + user.id, user)}>
                                                                            <AiOutlineTeam />
                                                                        </button>
                                                                        {/* 
                                                                            <button ref={btnDeleteRef} className='buttonIcon' onClick={(event) => deleteUser(event, 'Удалить', user)}>
                                                                                <AiOutlineDelete />
                                                                            </button>
                                                                            */}
                                                                        <button ref={btnEditRef} className='buttonIcon' onClick={(event) => editUser(event, 'Редактировать', user)}>
                                                                            <AiOutlineEdit />
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='resultSearchOutput'>Ни чего не найдено! Либо Вы ничего не ввели в строку поиска</div>
                                            )}
                                        </div>
                                    </div>
                                    {/* ПОИСКОВАЯ СТРОКА END */}
                                </>
                            ) : switchPage == 0 ? (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        width: 200,
                                        margin: 'auto',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingTop: 10,
                                        paddingBottom: 10
                                    }}>
                                        <div style={{
                                            width: 200,
                                            backgroundColor: '#ffffff',
                                            padding: 8,
                                            borderRadius: 8,
                                            marginRight: 5,
                                            borderWidth: 1,
                                            borderColor: '#2C7A7B',
                                            zIndex: 99,
                                            position: 'relative',
                                        }}>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange} // Обработчик для обновления состояния
                                                dateFormat="yyyy-MM-dd" // Формат отображения даты
                                                placeholderText="Выберите дату" // Плейсхолдер, если дата не выбрана
                                            />
                                        </div>

                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 5,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <FormLabel htmlFor="filter-switch" mb="0"
                                            style={{
                                                fontSize: 12,
                                                marginLeft: 10
                                            }}
                                        >
                                            От:
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            value={minSumm}
                                            onChange={handleMinSumm}
                                            style={{
                                                backgroundColor: '#ffffff',
                                                borderWidth: 1,
                                                borderColor: '#2C7A7B',
                                                width: 100
                                            }}
                                        />
                                        <FormLabel htmlFor="filter-switch" mb="0"
                                            style={{
                                                fontSize: 12,
                                                marginLeft: 10
                                            }}
                                        >
                                            До:
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            value={maxSumm}
                                            onChange={handleMaxSumm}
                                            style={{
                                                backgroundColor: '#ffffff',
                                                borderWidth: 1,
                                                borderColor: '#2C7A7B',
                                                marginRight: 5,
                                                width: 100
                                            }}
                                        />
                                        <FormControl display="flex" alignItems="center"
                                            style={{
                                                width: 300
                                            }}
                                        >
                                            <Switch
                                                colorScheme="teal"
                                                id="filter-switch"
                                                onChange={handleSwitchChange}
                                                isChecked={applyFilter === 1} // Управляемое состояние переключателя
                                            />
                                            <FormLabel htmlFor="filter-switch" mb="0"
                                                style={{
                                                    fontSize: 12,
                                                    marginLeft: 10
                                                }}
                                            >
                                                Включить фильтр по сумме NFT?
                                            </FormLabel>
                                        </FormControl>
                                        <Button
                                            className='homeSeachSubmit'
                                            colorScheme='teal'
                                            variant='outline'
                                            style={{
                                                width: 200
                                            }}
                                            onClick={() => {
                                                usersList(usertoken, 1, sort, sorting);
                                            }}
                                        //onClick={() => console.log(JSON.stringify(selectedDate, null, 1))}
                                        >
                                            {testSort}
                                        </Button>
                                    </div>
                                    {/* СПИСОК С СТРАНИЦАМИ */}
                                    <div className='panel' style={{ marginTop: 10 }}>
                                        <div className='headerPanel'>
                                            <div className='headerPanelRow'>
                                                <div className='headerPanelCol photoHeader'>
                                                    фото
                                                </div>
                                                <div className='headerPanelCol usernameHeader'>
                                                    username / idchat
                                                </div>
                                                <div className='headerPanelCol balanceHeader'>
                                                    баланс
                                                </div>
                                                <div className='headerPanelCol parentHeader'>
                                                    parent
                                                </div>
                                                <div className='headerPanelCol parentHeader'>
                                                    в 1 линии
                                                </div>
                                                <div className='headerPanelCol refcodeHeader'>
                                                    Реф. код
                                                </div>
                                                <div className='headerPanelCol dateHeader'>
                                                    Дата создания
                                                </div>
                                                <div className='headerPanelCol buttonsHeader'>
                                                    Управление
                                                </div>
                                            </div>
                                        </div>
                                        <div className='scrollingVerticale'>
                                            <div className='scrollingVerticaleInner'>
                                                {users && users.data && users.data.length > 0 ? (
                                                    users.data.map((user, index) => (
                                                        <div className='userItem' key={user.id}>
                                                            <div className='userId'>{user.id}</div>
                                                            <div className='userPhotoWrap'>
                                                                {user.user_photo ? (
                                                                    <img
                                                                        className='userPhoto'
                                                                        src={user.user_photo}
                                                                        alt="User Photo"
                                                                        onError={handleImageError}
                                                                    />
                                                                ) : (
                                                                    <img className='userPhoto' src="/images/ava_default.png" alt="No Photo Available" />
                                                                )}
                                                            </div>

                                                            <div className='userNameWrap'>
                                                                <div className='userName'>{user.username ? <span className="userNameIsName">{user.username} </span> : <span className="userNameNoName">Нет username</span>}</div>
                                                                <div className='userIdChat'>{user.idchat}</div>
                                                            </div>

                                                            <div className='userBalance'>
                                                                VaBank: {user.balance}<br />
                                                                USDT: {user.usdt}<br />
                                                                VBK: {user.vbk}<br />
                                                                VBK VEST: {user.vbk_vest}<br />
                                                                <div style={{ color: 'red' }}>NFT SUMM: {user.nft_total_price === null ? 0 : user.nft_total_price}</div>
                                                            </div>
                                                            <div className='userParent'>
                                                                {user.parent_user ? user.parent_user : '...'}
                                                            </div>
                                                            <div className='userCountLineOne'>
                                                                {user.child_count ? user.child_count : 0}
                                                            </div>
                                                            <div className='userRefCode'>{user.refcode}</div>
                                                            <div className='userCreaatedAt'>{format(parseISO(user.created_at), 'dd-MM-yyyy')}</div>
                                                            <div className='userButtons'>
                                                                <button ref={btnRef} className='buttonIcon' onClick={(event) => referalsUser(event, 'Рефералы пользователя c id ' + user.id, user)}>
                                                                    <AiOutlineTeam />
                                                                </button>
                                                                {/* 
                                                                <button ref={btnDeleteRef} className='buttonIcon' onClick={(event) => deleteUser(event, 'Удалить', user)}>
                                                                    <AiOutlineDelete />
                                                                </button>
                                                                */}
                                                                <button ref={btnEditRef} className='buttonIcon' onClick={(event) => editUser(event, 'Редактировать', user)}>
                                                                    <AiOutlineEdit />
                                                                </button>

                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>No users available</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className='paginations'>
                                            {users && users.links && users.links.length > 0 ? (
                                                users.links.map((link, index) => (
                                                    <button className={users.current_page == link.label ? 'page_active' : null} onClick={() => getPage(link.label)}>
                                                        {link.label}
                                                    </button>
                                                ))
                                            ) : (
                                                <p></p>
                                            )}
                                        </div>
                                    </div>
                                    {/* СПИСОК С СТРАНИЦАМИ END */}
                                </>
                            ) : switchPage == 2 ? (
                                <>
                                    {/* ПОЛЗОВАТЕЛИ С NFT */}
                                    <div className='panel' style={{ marginTop: 10 }}>
                                        <div className='headerPanel'>
                                            <div className='headerPanelRow'>
                                                <div className='headerPanelCol photoHeader'>
                                                    фото
                                                </div>
                                                <div className='headerPanelCol usernameHeader'>
                                                    username / idchat
                                                </div>
                                                <div className='headerPanelCol balanceHeader'>
                                                    баланс
                                                </div>
                                                <div className='headerPanelCol parentHeader'>
                                                    parent
                                                </div>
                                                <div className='headerPanelCol refcodeHeader'>
                                                    Реф. код
                                                </div>
                                                <div className='headerPanelCol dateHeader'>
                                                    Дата создания
                                                </div>
                                                <div className='headerPanelCol nftcount'>
                                                    кол-во NFT
                                                </div>
                                                <div className='headerPanelCol buttonsHeader'>
                                                    Управление
                                                </div>
                                            </div>
                                        </div>
                                        <div className='scrollingVerticale'>
                                            <div className='scrollingVerticaleInner'>
                                                {usersNft && usersNft.usersnfts && usersNft.usersnfts.length > 0 ? (
                                                    usersNft.usersnfts.map((usernftitem, index) => (
                                                        <div className='userItem' key={usernftitem.user.id}>
                                                            <div className='userId'>{usernftitem.user.id}</div>
                                                            <div className='userPhotoWrap'>
                                                                {usernftitem.user.user_photo ? (
                                                                    <img
                                                                        className='userPhoto'
                                                                        src={usernftitem.user.user_photo}
                                                                        alt="User Photo"
                                                                        onError={handleImageError}
                                                                    />
                                                                ) : (
                                                                    <img className='userPhoto' src="/images/ava_default.png" alt="No Photo Available" />
                                                                )}
                                                            </div>

                                                            <div className='userNameWrap'>
                                                                <div className='userName'>{usernftitem.user.username ? <span className="userNameIsName">{usernftitem.user.username} </span> : <span className="userNameNoName">Нет username</span>}</div>
                                                                <div className='userIdChat'>{usernftitem.user.idchat}</div>
                                                            </div>


                                                            <div className='userBalance'>
                                                                VaBank: {usernftitem.user.balance}<br />
                                                                USDT: {usernftitem.user.usdt}<br />
                                                                VBK: {usernftitem.user.vbk}<br />
                                                                VBK VEST: {usernftitem.user.vbk_vest}<br />

                                                            </div>
                                                            <div className='userParent'>{usernftitem.user.parent_user ? usernftitem.user.parent_user : '...'}</div>
                                                            <div className='userRefCode'>{usernftitem.user.refcode}</div>
                                                            <div className='userCreaatedAt'>{format(parseISO(usernftitem.user.created_at), 'dd-MM-yyyy')}</div>

                                                            <div className='userNftCount'>
                                                                {usernftitem.nfts.length}
                                                            </div>

                                                            <div className='userButtons'>
                                                                <button ref={btnRef} className='buttonIcon' onClick={(event) => referalsUser(event, 'Рефералы пользователя c id ' + usernftitem.user.id, usernftitem.user)}>
                                                                    <AiOutlineTeam />
                                                                </button>
                                                                {/* 
                                                                <button ref={btnDeleteRef} className='buttonIcon' onClick={(event) => deleteUser(event, 'Удалить', user)}>
                                                                    <AiOutlineDelete />
                                                                </button>
                                                                */}
                                                                <button ref={btnEditRef} className='buttonIcon' onClick={(event) => editUser(event, 'Редактировать', usernftitem.user)}>
                                                                    <AiOutlineEdit />
                                                                </button>

                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>No users available</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className='paginations'>
                                            {usersNft && usersNft.links && usersNft.links.length > 0 ? (
                                                usersNft.links.map((link, index) => (
                                                    <button className={usersNft.current_page == link.label ? 'page_active' : null} onClick={() => getPageNft(link.label)}>
                                                        {link.label}
                                                    </button>
                                                ))
                                            ) : (
                                                <p></p>
                                            )}
                                        </div>
                                    </div>
                                    {/* ПОЛЗОВАТЕЛИ С NFT END */}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='errorWrap'>
                    <div className='error'>ERROR 401</div>
                </div>
            )}

            {/* Drawer панель Рефералы */}
            <Drawer
                isOpen={referalsDisclosure.isOpen}
                placement='right'
                onClose={referalsDisclosure.onClose}
                finalFocusRef={btnRef}
                size={'full'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        <>
                            {titleDrawer}
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                                {countReferals !== null && countReferals !== undefined ? (
                                    <>
                                        <div style={{ color: '#999999', fontSize: 13 }}>
                                            {countReferals['1'] ? '1 линия - ' + countReferals['1'] + ' шт.' : null}
                                        </div>

                                        <div style={{ color: '#999999', fontSize: 13 }}>
                                            {countReferals['2'] ? '2 линия - ' + countReferals['2'] + ' шт.' : null}
                                        </div>
                                        <div style={{ color: '#999999', fontSize: 13 }}>
                                            {countReferals['3'] ? '3 линия - ' + countReferals['3'] + ' шт.' : null}
                                        </div>
                                        <div style={{ color: '#999999', fontSize: 13 }}>
                                            {countReferals['4'] ? '4 линия - ' + countReferals['4'] + ' шт.' : null}
                                        </div>
                                        <div style={{ color: '#999999', fontSize: 13 }}>
                                            {countReferals['5'] ? '5 линия - ' + countReferals['5'] + ' шт.' : null}
                                        </div>

                                        <div style={{ color: 'red', fontSize: 13, fontWeight: 'bold' }}>
                                            {countReferals !== null && countReferals !== undefined
                                                ? 'Всего - ' + Object.values(countReferals).reduce((sum, count) => sum + count, 0) + ' шт.'
                                                : null}
                                        </div>
                                    </>
                                ) : null}
                            </div>

                        </>
                    </DrawerHeader>

                    <DrawerBody>
                        {userReferals && userReferals?.pagination && userReferals?.pagination?.data && userReferals?.pagination?.data.length > 0 ? (
                            <div className='scrollingVerticale'>
                                <div className='scrollingVerticaleInner referalsWrap'>
                                    <div className='headerPanel'>
                                        <div className='headerPanelRow'>
                                            <div className='headerPanelCol photoHeader'>
                                                фото
                                            </div>
                                            <div className='headerPanelCol usernameHeader'>
                                                username / idchat
                                            </div>
                                            <div className='headerPanelCol balanceHeader'>
                                                баланс
                                            </div>
                                            <div className='headerPanelCol parentHeader'>
                                                parent
                                            </div>
                                            <div className='headerPanelCol refcodeHeader'>
                                                Реф. код
                                            </div>
                                            <div className='headerPanelCol dateHeader'>
                                                Дата создания
                                            </div>
                                        </div>
                                    </div>

                                    {userReferals?.pagination?.data.map((user, index) => (
                                        <TemplateReferal key={index} user={user} parent={curentUserReferal} />
                                    ))}

                                    <div className='paginations'>
                                        {userReferals && userReferals?.pagination && userReferals?.pagination?.data && userReferals?.pagination?.data.length > 0 ? (
                                            userReferals?.pagination?.links.map((link, index) => (
                                                <button className={userReferals?.pagination?.current_page == link.label ? 'page_active' : null} onClick={() => getPageReferals(link.label, curentUserReferal)}>
                                                    {link.label}
                                                </button>
                                            ))
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            userReferals == 'wait' ? (<p style={{ color: '#999999' }}><Spinner color='red.300' style={{ marginRight: 15 }} />Подождите загружаем список рефералов...</p>) : (<p>Нет рефералов</p>)
                        )}
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={referalsDisclosure.onClose}>
                            Закрыть
                        </Button>

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            {/* Drawer панель редактировать пользователя */}
            <Drawer
                isOpen={editDisclosure.isOpen}
                placement='right'
                onClose={editDisclosure.onClose}
                finalFocusRef={btnEditRef}
                size={'xl'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Редактирование</DrawerHeader>

                    <DrawerBody>

                        <div className='editUserWrap'>
                            <FormControl>
                                <div className='formImageUserWrap'>
                                    {infoUser?.user_photo ? (
                                        <img
                                            className='userPhotoBig'
                                            src={infoUser?.user_photo}
                                            alt="User Photo"
                                            onError={handleImageError}
                                        />
                                    ) : (
                                        <img className='userPhotoBig' src="/images/ava_default.png" alt="No Photo Available" />
                                    )}
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                    <Button variant='outline' ref={btnRef} onClick={(event) => referalsUser(event, 'Рефералы пользователя c id ' + infoUser?.id, infoUser)}>
                                        Список рефералов
                                    </Button>
                                </div>

                                <Tabs isLazy position='relative' variant='unstyled'>
                                    <TabList>
                                        <Tab>ПОЛЬЗОВАТЕЛЬ</Tab>
                                        <Tab>NFT СПИСОК</Tab>
                                    </TabList>
                                    <TabIndicator mt='-1.5px' height='2px' bg='blue.500' borderRadius='1px' />

                                    <TabPanels>
                                        <TabPanel>
                                            <div className='formFormRow'>
                                                <div className='formLabel'>USERNAME</div>
                                                <div className='formTextValue'>{infoUser?.username}</div>
                                            </div>

                                            <div className='formFormRow'>
                                                <div className='formLabel'>IDCHAT</div>
                                                <div className='formTextValue'>{infoUser?.idchat}</div>
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>Реферальный код</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputRefCode}
                                                    onChange={handleRefCode}
                                                    className='formTextValue'
                                                />
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>Прокруты</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputScrolling}
                                                    onChange={handleScrolling}
                                                    className='formTextValue'
                                                />
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>Баланс монет</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputBalance}
                                                    onChange={handleBalance}
                                                    className='formTextValue'
                                                />
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>Уровень</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputLevel}
                                                    onChange={handleLevel}
                                                    className='formTextValue'
                                                />
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>Parent</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputParent_user}
                                                    onChange={handleParent_user}
                                                    className='formTextValue'
                                                />
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>UID</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputBybit_link}
                                                    onChange={handleBybit_link}
                                                    className='formTextValue'
                                                />
                                            </div>

                                            <div className='formFormRow'>
                                                <FormLabel className='formLabel'>ByBit ссылка</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={inputBybit_reflink}
                                                    onChange={handleBybit_reflink}
                                                    className='formTextValue'
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15, marginTop: 15 }}>
                                                <div>
                                                    <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
                                                        {nftUser && nftUser.map((nft, index) => (
                                                            <Card key={nft.id}>
                                                                <CardHeader>
                                                                    <Heading size='md'>{nft.price}$ - {nft.procent}%</Heading>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Text>{nft.created_at}</Text>
                                                                </CardBody>
                                                            </Card>
                                                        ))}
                                                        {nftUser.length == 0 ? (
                                                            <div>Нет NFT</div>
                                                        ) : null}
                                                    </SimpleGrid>
                                                </div>
                                            </div>
                                        </TabPanel>

                                    </TabPanels>
                                </Tabs>
                            </FormControl>

                        </div>

                    </DrawerBody>

                    <DrawerFooter>
                        <Button colorScheme='teal' variant='solid' mr={3} onClick={() => saveInfoUser(usertoken, infoUser?.idchat, inputRefCode, inputScrolling, inputBalance, inputLevel, inputParent_user, inputBybit_link, inputBybit_reflink)}>
                            Сохранить
                        </Button>
                        <Button variant='outline' mr={3} onClick={editDisclosure.onClose}>
                            Закрыть
                        </Button>

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

        </>
    );
});

export default Users;

import React, { useRef } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,          // Добавляем импорт Button
    Input,           // Добавляем импорт Input
    useDisclosure    // Добавляем импорт useDisclosure
} from '@chakra-ui/react';

import { PhoneIcon, AddIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons'

//Стили
import '../css/header.css';


const Header = observer(({ title }) => {
    return (
        <>
            <div className='main-header flexrow'>
                <div className='main-header-title'>
                    {title}
                </div>
            </div>
        </>
    );
});

export default Header;
import React, { useRef } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,          // Добавляем импорт Button
    Input,           // Добавляем импорт Input
    useDisclosure    // Добавляем импорт useDisclosure
} from '@chakra-ui/react';

import { PhoneIcon, AddIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons'

//Стили
import '../css/sidebar.css';



const SideBar = observer(() => {
    return (
        <>
            <div className='sidebarWrap'>
                <div className='sidebar-logo'>
                    <img className='sidebar-logo-image' src='/images/moneta.png' alt="" />
                </div>

                <div className='sidebar-links'>
                    <ul>
                        <li><Link to="/home"><ArrowForwardIcon boxSize={4} /><span>Панель</span></Link></li>
                        <li><Link to="/users"><ArrowForwardIcon boxSize={4} /><span>Пользователи</span></Link></li>
                        <li><Link to="/tasks"><ArrowForwardIcon boxSize={4} /><span>Задания</span></Link></li>
                        <li><Link to="/mailing"><ArrowForwardIcon boxSize={4} /><span>Рассылка</span></Link></li>
                        <li><Link to="/withdrawal"><ArrowForwardIcon boxSize={4} /><span>Выводы</span></Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
});

export default SideBar;
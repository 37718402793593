import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { observer } from "mobx-react-lite";

//Конфиг
import config from '../config';

import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Button,
    ButtonGroup,
    Input,
    useDisclosure
} from '@chakra-ui/react';


//Стили
import '../css/main.css';
import '../css/login.css';

//Сторы
import PlayerStore from '../store/playerStore';

const Login = observer(() => {
    //Для навигации
    const navigate = useNavigate(); // Создаем экземпляр navigate

    //Данные для формы
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData({
            ...loginData,
            [name]: value
        });
    };

    //Функция срабатывает при отправки формы авторизации
    const [isLoading, setIsLoading] = useState(false); //Стейт для кнопки отправить (включает анимацию загрузки)
    const [errorForm, setErrorForm] = useState(null); //Стейт для ошибков форме после отправки

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(JSON.stringify(loginData, null, 1));

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        //Отправляемые даныне
        const dataRec = {
            'username': loginData.username,
            'password': loginData.password
        };

        axios.post(config.apiBaseUrl + '/api/api/v1/loginadmin', dataRec, { headers })
            .then(response => {
                console.log('АВТОРИЗАЦИЯ В АДМИНКЕ: ', JSON.stringify(response.data, null, 1));
                if (response.data.error) {
                    setIsLoading(false);
                    console.log('Ошибка авторизации!');
                    setErrorForm(response.data.message);
                }
                if (!response.data.error) {
                    setIsLoading(false);
                    console.log('Успешный вход в панель!');
                    setErrorForm(response.data.message);

                    //Сохраняем токен в стейт============
                    PlayerStore.setPlayer(response.data);
                    console.log(JSON.stringify(PlayerStore, null, 1));
                    //=================================== END

                    // Функция для сохранения token===
                    const saveToken = async (token) => {
                        try {
                            await AsyncStorage.setItem('token', token);
                            console.log('Token saved successfully');
                        } catch (error) {
                            console.error('Error saving token', error);
                        }
                    };
                    saveToken(response.data.token);
                    //================================

                    setTimeout(() => {
                        navigate('/home'); //Переходим по адресу в админку
                    }, 1500);
                }
            })
            .catch(error => {
                setIsLoading(false);
                setErrorForm(error.response.data.message);
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);

                }
            });


        //try {
        //    const response = await axios.post('https://your-server.com/login', loginData);
        //    console.log('Login success:', response.data);
        //    // Здесь можно обработать успешный ответ
        //} catch (error) {
        //    console.error('Login failed:', error);
        //    // Здесь можно обработать ошибку
        //}
    };

    return (

        <div className='formMain'>
            <div className='formWrap'>
                <form className='form' onSubmit={handleSubmit}>
                    <div className='formTitle'>ADMIN</div>
                    <div className='formSubTitle'>VaBank</div>
                    <div className='inputForm'>
                        <FormControl isRequired>
                            <label className='inputFormLabel' htmlFor="username">Логин:</label>
                            <input
                                className='inputFormText'
                                type="text"
                                id="username"
                                name="username"
                                value={loginData.username}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormErrorMessage>Обязательное поле!</FormErrorMessage>
                    </div>
                    <div className='inputForm'>
                        <FormControl isRequired>
                            <label className='inputFormLabel' htmlFor="password">Пароль:</label>
                            <input
                                className='inputFormText'
                                type="password"
                                id="password"
                                name="password"
                                value={loginData.password}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormErrorMessage>Обязательное поле!</FormErrorMessage>
                    </div>
                    <Button
                        colorScheme='teal'
                        size='lg'
                        width='100%'
                        type='submit'
                        isLoading={isLoading}
                        loadingText='Подожди...'
                    >
                        ВОЙТИ
                    </Button>
                    <div className='errorForm'>{errorForm}</div>
                </form>
            </div>

        </div>

    );
});

export default Login;

import React, { useRef, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { format, parseISO } from 'date-fns';

//Конфиг
import config from '../config';

import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    useToast,
    Spinner,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Input,
    useDisclosure,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';

//Иконки
import {
    AiOutlineUser,
    AiOutlineEye,
    AiOutlineTeam,
    AiOutlineDelete,
    AiOutlineEdit
} from "react-icons/ai";

import { PhoneIcon, AddIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons'

//Стили
import '../css/main.css';
import '../css/tasks.css';

//Компоненты
import SideBar from '../components/SideBar';
import Header from '../components/Header';

const Tasks = observer(() => {

    // Функция для получения token
    const [usertoken, setUsertoken] = useState(null);
    const getToken = async () => {
        try {
            const utoken = await AsyncStorage.getItem('token');
            if (utoken !== null) {
                console.log('Token retrieved:', utoken);
                setUsertoken(utoken)
                return utoken;
            }
        } catch (error) {
            console.error('Error retrieving token', error);
        }
    };
    useEffect(() => {
        getToken();
    }, []);


    //Получаем список заданий
    const [tasks, setTasks] = useState(null);
    const tasksList = (t) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/tasks`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setTasks(response.data);
                console.log('СПИСОК ЗАДАНИЯ: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка списка заданий 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка списка заданий:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка списка заданий:', error.message);
                }
            });
    }
    //Вызываем список пользователей при загрузке страницы
    useEffect(() => {
        if (usertoken) {
            tasksList(usertoken); //Токен / page / включение сортировки / направление сортировки asc или desc
        }
    }, [usertoken]);

    //Проверяем существование фото
    const handleImageError = (event) => {
        event.target.src = '/images/moneta.png';
    };

    //Drawer==========
    const editDisclosure = useDisclosure();
    const [titleDrawer, setTitleDrawer] = useState('...');

    //Кнопки для таска (задания) редактировать и тд
    const btnEditRef = useRef();

    //Функция редактировать задания
    const editTask = (event, title, task) => {
        //event.preventDefault();
        setTitleDrawer(title);
        editDisclosure.onOpen();
    }


    return (
        <>
            {usertoken ? (
                <div className='main flexrow'>
                    <div className='sidebar flexcolumn'>
                        <SideBar />
                    </div>
                    <div className='main-contents'>
                        <Header title={'Задания'} />
                        <div className='main-blok'>

                            {/* СПИСОК ЗАДАНИЙ */}
                            <div className='panel'>
                                <div className='scrollingVerticale'>
                                    <div className='panelPaddings'>

                                        {tasks && tasks.map((task, index) => (
                                            <div key={index} className="taskItemWrap">
                                                <div className="taskItemRow">
                                                    <div className="taskItemColFirst">
                                                        <div className="taskItemTitle">
                                                            {task.title}
                                                        </div>
                                                        <div className="taskItemParamsRow">
                                                            {task.awardspins != 0 ? (
                                                                <div className="taskItemParamsCol">
                                                                    + {task.awardspins} scrolling
                                                                </div>
                                                            ) : null}
                                                            {task.awardmonets != 0 ? (
                                                                <div className="taskItemParamsCol">
                                                                    + {task.awardmonets}
                                                                    <img
                                                                        className='imageMoneta'
                                                                        src={'/images/moneta.png'}
                                                                        alt="vabank"
                                                                        onError={handleImageError}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="taskItemColLast">
                                                        <img
                                                            className='imageTask'
                                                            src={config.apiBaseUrl + task.image}
                                                            alt="Изображение задания"
                                                            onError={handleImageError}
                                                        />
                                                    </div>
                                                    <div className="taskItemButtons">
                                                        <button ref={btnEditRef} className='buttonIcon' onClick={(event) => editTask(event, 'Редактировать', task)}>
                                                            <AiOutlineEdit />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* СПИСОК ЗАДАНИЙ END */}

                        </div>
                    </div>
                </div>
            ) : (
                <div className='errorWrap'>
                    <div className='error'>ERROR 401</div>
                </div>
            )}



            {/* Drawer панель редактировать пользователя */}
            <Drawer
                isOpen={editDisclosure.isOpen}
                placement='right'
                onClose={editDisclosure.onClose}
                finalFocusRef={btnEditRef}
                size={'xl'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Редактирование</DrawerHeader>

                    <DrawerBody>

                        <div className='editUserWrap'>
                            <FormControl>

                                <div className='formFormRow'>
                                    <div className='formLabel'>Название</div>
                                    <div className='formTextValue'>Компонент в разработке. Зайдите позже.</div>
                                </div>


                            </FormControl>

                        </div>

                    </DrawerBody>

                    <DrawerFooter>
                        <Button colorScheme='teal' variant='solid' mr={3} onClick={() => console.log('Сохранить задание!')}>
                            Сохранить
                        </Button>
                        <Button variant='outline' mr={3} onClick={editDisclosure.onClose}>
                            Закрыть
                        </Button>

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

        </>
    );
});

export default Tasks;

import { makeAutoObservable } from "mobx";


class PlayerStore {

    player = {};

    //Делаем отслеживаемость
    constructor() {
        makeAutoObservable(this)
    }

    //Добавляем
    editPlayerStore(attr, value) {
        this.player[attr] = value;
    }

    //Метод для полной замены объекта player
    setPlayer(newPlayerData) {
        this.player = { ...newPlayerData };
    }

}

const playerStore = new PlayerStore();
export default playerStore; 
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider, extendTheme, CSSReset, ColorModeScript } from '@chakra-ui/react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

// Компоненты
import Home from './screens/Home';
import Login from './screens/Login';
import Users from './screens/Users';
import Tasks from './screens/Tasks';
import Mailing from './screens/Mailing';
import Withdrawal from './screens/Withdrawal';


function App() {

  //ОЧИСТКА localStorage===========
  useEffect(() => {
    // Полностью очистить localStorage при монтировании компонента
    localStorage.clear();

    // Или удалить конкретный элемент по ключу
    // localStorage.removeItem('chakra-ui-color-mode');
  }, []);
  //================================

  // Создаем тему, устанавливая светлый режим по умолчанию
  const theme = extendTheme({
    config: {
      initialColorMode: 'light', // Устанавливаем светлую тему по умолчанию
      useSystemColorMode: false,  // Отключаем использование системной темы
    }

  });

  return (
    <>
      <TonConnectUIProvider manifestUrl="https://vabankgame.ru/tonconnect-manifest.json">
        {/* Этот компонент гарантирует, что цветовая схема будет загружена правильно */}
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
          <CSSReset />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/users" element={<Users />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/mailing" element={<Mailing />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
          </Routes>
        </ChakraProvider>
      </TonConnectUIProvider>
    </>
  );
}

export default App;

import React, { useRef, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { format, parseISO } from 'date-fns';

//Конфиг
import config from '../config';

import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    useToast,
    Spinner,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Input,
    useDisclosure,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';

import { PhoneIcon, AddIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons'

//Стили
import '../css/main.css';
import '../css/home.css';
import '../css/users.css';

//Компоненты
import SideBar from '../components/SideBar';
import Header from '../components/Header';

const Home = observer(() => {

    // Храним токен
    const [usertoken, setUsertoken] = useState(null);

    // Храним статистику
    const [statistic, setStatistic] = useState(null);

    // Функция для получения токена
    const getToken = async () => {
        try {
            const utoken = await AsyncStorage.getItem('token');
            if (utoken !== null) {
                console.log('Token retrieved:', utoken);
                setUsertoken(utoken); // Устанавливаем токен в стейт
                return utoken;
            }
        } catch (error) {
            console.error('Error retrieving token', error);
        }
    };

    // Запрос статистики с сервера
    const getStats = (token) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/getstats`;

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setStatistic(response.data); // Сохраняем данные в стейт
                console.log('СТАТИСТИКА: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    console.error('Ошибка СТАТИСТИКИ 2xx:', error.response.data);
                } else if (error.request) {
                    console.error('Ошибка запроса СТАТИСТИКИ:', error.request);
                } else {
                    console.error('Ошибка СТАТИСТИКИ:', error.message);
                }
            });
    };

    // Получаем токен при первом рендере
    useEffect(() => {
        getToken();
    }, []);

    // Когда токен получен, запрашиваем статистику
    useEffect(() => {
        if (usertoken) {
            getStats(usertoken);
        }
    }, [usertoken]);

    //Поиск по списку
    const [inputRefSearch, setInputRefSearch] = useState(null);

    // Обработчик изменения текста в поле ввода
    const handleRefSearch = (event) => {
        setInputRefSearch(event.target.value);
        console.log(event.target.value);
    }
    //Отправка в поиск
    const [searchResult, setSearchResult] = useState([]);
    const search = (t, value) => {
        const apiUrl = `https://vabankgame.ru/api/api/v1/searchuser?search=${value}`;

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        ////Отправляемые даныне
        //const dataRec = {
        //};

        axios.get(apiUrl, { headers })
            .then(response => {
                console.log('ПОИСК ПОЛЬЗОВАТЕЛЯ: ', JSON.stringify(response.data, null, 1));
                if (response.data.errorcode == 0) {
                    setSearchResult(response.data.users);
                }
                else {
                    setSearchResult([]);
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка ПОИСКА ПОЛЬЗОВАТЕЛЯ 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка ПОИСКА ПОЛЬЗОВАТЕЛЯ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка ПОИСКА ПОЛЬЗОВАТЕЛЯ:', error.message);
                }
            });
    }




    return (
        <>
            {usertoken ? (
                <div className='main flexrow'>
                    <div className='sidebar flexcolumn'>
                        <SideBar />
                    </div>
                    <div className='main-contents'>
                        <Header title={'Панель'} />
                        <div className='main-blok'>

                            <div className='homeRow'>

                                <div className='homeCol'>
                                    <Stat className='homeStat'>
                                        <StatLabel>Колл-во пользователей</StatLabel>
                                        <StatNumber>{statistic?.totalusers}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                    </Stat>
                                </div>

                                <div className='homeCol'>
                                    <Stat className='homeStat'>
                                        <StatLabel>Сумма всех VaBak монет у пользователей</StatLabel>
                                        <StatNumber>{statistic?.totalbalance}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                    </Stat>
                                </div>

                                <div className='homeCol'>
                                    <Stat className='homeStat'>
                                        <StatLabel>Колл-во USDT у пользователей</StatLabel>
                                        <StatNumber>{statistic?.totalUsdt}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                    </Stat>
                                </div>

                            </div>

                            <div className='homeRow'>
                                <div className='homeCol'>
                                    <Stat className='homeStat'>
                                        <StatLabel>Сумма всех выводов в USDT</StatLabel>
                                        <StatNumber>{statistic?.totalСashwithdrawals}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                    </Stat>
                                </div>

                                <div className='homeCol'>
                                    <Stat className='homeStat'>
                                        <StatLabel>Колл-во NFT у пользователей</StatLabel>
                                        <StatNumber>{statistic?.totalUserNft}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                    </Stat>
                                </div>

                                <div className='homeCol'>
                                    <Stat className='homeStat'>
                                        <StatLabel>Задания</StatLabel>
                                        <StatNumber>{statistic?.totalTasks}</StatNumber>
                                        <StatHelpText></StatHelpText>
                                    </Stat>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            ) : (
                <div className='errorWrap'>
                    <div className='error'>ERROR 401</div>
                </div>
            )}
        </>
    );
});

export default Home;

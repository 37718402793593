import React, { useRef, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { format, parseISO } from 'date-fns';

//Конфиг
import config from '../config';

import {
    Stack,
    Switch,
    Tag,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    useToast,
    Spinner,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Input,
    Textarea,
    useDisclosure,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react';

//Сторы
import playerStore from '../store/playerStore';

//Иконки
import {
    AiOutlineUser,
    AiOutlineEye,
    AiOutlineTeam,
    AiOutlineDelete,
    AiOutlineEdit
} from "react-icons/ai";

import { PhoneIcon, AddIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons'

//Стили
import '../css/main.css';
import '../css/mailing.css';

//Компоненты
import SideBar from '../components/SideBar';
import Header from '../components/Header';

const Mailing = observer(() => {

    //Функция запрашивает статистику с сервера
    const [statistic, setStatistic] = useState(null);
    const getStats = (t) => {
        const apiUrl = config.apiBaseUrl + `/api/api/v1/getstats`;


        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${t}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                setStatistic(response.data);
                console.log('СТАТИСТИКА: ', JSON.stringify(response.data, null, 1));
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка СТАТИСТИКИ 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса СТАТИСТИКИ:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка СТАТИСТИКИ:', error.message);
                }
            });
    }
    useEffect(() => {
        getStats(playerStore?.player?.token);
    }, []);

    //Для формы
    //Форма редактирования пользователя
    const [inputRefNomer, setInputRefNomer] = useState(0);
    const [inputRefTextButton, setInputRefTextButton] = useState('TAPlay to earn');
    const [inputRefComandButton, setInputComandButton] = useState(config.apiBaseUrl);
    const [inputRefTextArea, setInputTextArea] = useState(null);
    const [inputRefTestUser, setInputRefTestUser] = useState('711119211');
    const [testMode, setTestMode] = useState(0); // Начальное значение 0 (выключен) ТЕСТ РЕЖИМ

    // Обработчик изменения текста в поле ввода
    const handleSwitchChange = (event) => {
        // Устанавливаем 1 если включен, 0 если выключен
        setTestMode(event.target.checked ? 1 : 0);
        console.log('тест режим: ', event.target.checked);
    };

    const handleRefTestUser = (event) => {
        setInputRefTestUser(event.target.value);
        console.log(event.target.value);
    }

    const handleRefNomer = (event) => {
        setInputRefNomer(event.target.value);
        console.log(event.target.value);
    }

    const handleRefTextButton = (event) => {
        setInputRefTextButton(event.target.value);
        console.log(event.target.value);
    }

    const handleRefComandButton = (event) => {
        setInputComandButton(event.target.value);
        console.log(event.target.value);
    }

    const handleRefTextArea = (event) => {
        setInputTextArea(event.target.value);
        console.log(event.target.value);
    }

    //Загрузка картинки=========
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileName, setFileName] = useState(null);
    const [uploadImageOutput, setUploadImageOutput] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Проверяем формат файла
        if (file && !['image/jpeg', 'image/png'].includes(file.type)) {
            alert('Пожалуйста, загрузите изображение в формате JPG или PNG.');
            setSelectedFile(null);
            return;
        }
        setUploadProgress(0);
        setFileName(file.name); // Устанавливаем имя файла в состояние
        setSelectedFile(file);
    };

    const fileInputRef = useRef(null); // Создаем ref для input
    const handleFileClick = () => {
        fileInputRef.current.click(); // Открываем диалог выбора файла
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            console.log("Пожалуйста, выберите файл");
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post(config.apiBaseUrl + '/api/api/v1/uploadimage', formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${playerStore?.player?.token}` // Добавляем токен в заголовок Authorization
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted); // Устанавливаем прогресс загрузки
                },
            });
            if (response?.data?.url) {
                setUploadImageOutput(response?.data?.url);
            }
            console.log('Ответ сервера:', response.data);
        } catch (error) {
            if (error.response) {
                console.error('Ошибка загрузки файла (ответ сервера):', error.response.data);
            } else {
                console.error('Ошибка загрузки файла (ошибка запроса):', error.message);
            }
        }
    };
    //==========================

    //Старт рассылки============
    const [messages, setMessages] = useState([]);
    const [isMailing, setIsMailing] = useState(false); // Статус рассылки
    const [offset, setOffset] = useState(0); // Начальное смещение

    const startMailing = (newOffset) => {

        //// Начинаем опрашивание для получения сообщений
        //const pollingInterval = setInterval(() => {
        //    pollMessages(pollingInterval); // Передаем pollingInterval для очистки
        //}, 3000); // Опрашиваем каждые 3 секунды

        setIsMailing(true); // Устанавливаем статус рассылки
        const apiUrl = config.apiBaseUrl + `/api/api/v1/sendmeiling`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${playerStore.player.token}` // Замените playerStore.player.token на ваш токен
        };
        console.log(
            'testmode:', testMode,
            'test_user:', inputRefTestUser,
            'offset:', newOffset,
            'text:', inputRefTextArea,
            'caption:', uploadImageOutput,
            'comand:', inputRefComandButton,
            'comandtext:', inputRefTextButton
        );
        const dataRec = {
            testmode: testMode,
            test_user: inputRefTestUser,
            offset: newOffset,
            text: inputRefTextArea,
            caption: uploadImageOutput,
            comand: inputRefComandButton,
            comandtext: inputRefTextButton
        };

        // Запускаем рассылку через API
        axios.post(apiUrl, dataRec, { headers })
            .then(response => {
                console.log('ОТВЕТ ПО РАССЫЛКЕ: ', response?.data);
                console.log('ОТВЕТ ПО РАССЫЛКЕ MESSAGE: ', response?.data?.message);
                console.log('offset: ', offset);

                const countUser = response?.data?.count_user || 0; // Получаем количество пользователей

                if (response?.data?.status == 'success') {
                    setMessages((prevMessages) => [...prevMessages, response?.data?.message]);
                    setOffset((prevOffset) => prevOffset + countUser);

                    if (response?.data?.statusMailing != true) {
                        setTimeout(() => {
                            startMailing(newOffset + countUser);
                        }, 10000);
                    }
                }

                // Останавливаем опрос при отключении рассылки
                //return () => clearInterval(pollingInterval);
            })
            .catch(error => {
                console.error('Ошибка СТАРТА РАССЫЛКИ:', error);
            });
    };

    //const pollMessages = (pollingInterval) => {
    //    const apiUrl = config.apiBaseUrl + `/api/api/v1/getmailingstatus`; // URL вашего нового метода
    //    const headers = {
    //        'Accept': 'application/json',
    //        'Content-Type': 'application/json',
    //        'Authorization': `Bearer ${playerStore.player.token}`
    //    };
    //    axios.get(apiUrl, { headers })
    //        .then(response => {
    //            const newMessages = response.data.status; // Извлекаем статус
    //            const currentUser = response.data.current_user; // Получаем текущего пользователя
    //            if (currentUser) {
    //                // Добавляем текущего пользователя к статусу
    //                setMessages((prevMessages) => [...prevMessages, { user: currentUser, status: 'processing' }]);
    //            }
    //            console.log('ОПРОС СЕРВЕРА: ', response?.data);
    //            setOffset(prevOffset => prevOffset + response?.data?.count_user);
    //        })
    //        .catch(error => {
    //            console.error('Ошибка при опросе сообщений:', error);
    //        });
    //};

    //// Вызов pollMessages в startMailing как до этого:
    //const pollingInterval = setInterval(() => {
    //    pollMessages();
    //}, 1000); // Опрашиваем каждые 5 секунд
    //Старт рассылки============ END

    return (
        <>
            {playerStore?.player?.token ? (
                <div className='main flexrow'>
                    <div className='sidebar flexcolumn'>
                        <SideBar />
                    </div>
                    <div className='main-contents'>
                        <Header title={'Рассылка'} />
                        <div className='main-blok'>

                            {/* РАССЫЛКА */}
                            <div className='panel'>
                                <div className='scrollingVerticale'>
                                    <div className='panelPaddings'>
                                        <div className='bloksRows'>
                                            <div className='bloksColFirst'>
                                                <div className='countUsers'>Всего: {statistic?.totalusers} пользователей</div>

                                                <div className='formFormRow'>
                                                    <Stack align='center' direction='row'>
                                                        <Switch
                                                            size='md'
                                                            colorScheme='teal'
                                                            onChange={handleSwitchChange}
                                                        />
                                                        <Tag>Тест режим</Tag>
                                                    </Stack>
                                                </div>
                                                <div className='formFormRow'>
                                                    <FormLabel className='formLabel'>idchat пользователя которому нужно отправить тестовые сообщения</FormLabel>
                                                    <Input
                                                        type="text"
                                                        value={inputRefTestUser}
                                                        onChange={handleRefTestUser}
                                                        className='formTextValue'
                                                    />
                                                </div>

                                                <div className='bloksRows'>

                                                    <div className='formFormRow'>
                                                        <FormLabel className='formLabel'>Номер пользователя</FormLabel>
                                                        <Input
                                                            type="text"
                                                            value={inputRefNomer}
                                                            onChange={handleRefNomer}
                                                            className='formTextValue'
                                                        />
                                                    </div>

                                                    <div className='formFormRow'>
                                                        <FormLabel className='formLabel'>Начать рассылку</FormLabel>
                                                        <Button
                                                            colorScheme='teal'
                                                            variant='solid'
                                                            mr={3}
                                                            onClick={() => startMailing(offset)}
                                                            disabled={isMailing}>
                                                            {isMailing ? 'Рассылка запущена...' : 'Запустить рассылку'}
                                                        </Button>
                                                    </div>

                                                </div>

                                                <div className='bloksRows'>
                                                    <div className='formFormRow'>
                                                        <FormLabel className='formLabel'>Текст на кнопке</FormLabel>
                                                        <Input
                                                            type="text"
                                                            value={inputRefTextButton}
                                                            onChange={handleRefTextButton}
                                                            className='formTextValue'
                                                        />
                                                    </div>
                                                    <div className='formFormRow'>
                                                        <FormLabel className='formLabel'>Переход по кнопке</FormLabel>
                                                        <Input
                                                            type="text"
                                                            value={inputRefComandButton}
                                                            onChange={handleRefComandButton}
                                                            className='formTextValue'
                                                        />
                                                    </div>
                                                </div>

                                                <div className='formUploadImage'>
                                                    <form onSubmit={handleSubmit}>

                                                        <div className='bloksRows'>
                                                            <input
                                                                type="file"
                                                                onChange={handleFileChange}
                                                                style={{ display: 'none' }} // Скрываем стандартный input
                                                                ref={fileInputRef} // Привязываем ref
                                                            />
                                                            <Button colorScheme='teal' variant='solid' mr={3} onClick={handleFileClick}>
                                                                Выберите файл
                                                            </Button>
                                                            <Button colorScheme='teal' variant='solid' mr={3} type="submit">Загрузить</Button>
                                                        </div>
                                                        <div className='bloksRows'>
                                                            {fileName != null ? (
                                                                <div className='formUploadProgress'>{fileName}</div>
                                                            ) : null}
                                                            {uploadProgress > 0 && <div className='formUploadProgress'>Загрузка: {uploadProgress}%</div>}
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className='uploadImageOutput'>
                                                    {uploadImageOutput != null ? (
                                                        <img className='uploadImageOutputImg' src={uploadImageOutput} alt="" />
                                                    ) : null}
                                                </div>

                                                <div className='bloksRows'>
                                                    <div className='formTextAreaWrap'>
                                                        <Textarea
                                                            className='formTextArea'
                                                            rows={10}
                                                            placeholder='Текст рассылки. Не больше 1000 знаков включая пробелы.'
                                                            size='lg'
                                                            value={inputRefTextArea}
                                                            onChange={handleRefTextArea}
                                                            resize={'vertical'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bloksColLast'>
                                                <div className='responses_from_server'>
                                                    <div className='responses_from_server_title'>
                                                        Ответы от сервера
                                                    </div>
                                                    {messages.map((message, index) => (
                                                        <div style={{
                                                            color: 'yellow',
                                                            marginBottom: 5,
                                                            fontSize: 12
                                                        }} key={index}>{index}: {message}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* РАССЫЛКА END */}
                        </div>
                    </div>
                </div>
            ) : (
                <div className='errorWrap'>
                    <div className='error'>ERROR 401</div>
                </div>
            )}


        </>
    );
});

export default Mailing;
